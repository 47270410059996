import React, { useState, useEffect } from 'react';
import {
    MDBNavbar,
    MDBContainer,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBCollapse,
    MDBNavbarBrand,
    MDBBtn,
} from 'mdb-react-ui-kit';
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import { Divide as Hamburger } from 'hamburger-react';
import './AdminNavbar.css';
import Logo from '../../../assets/logo.png';



const AdminNavbar = () => {
        const navigate = useNavigate(); // Define navigate using useNavigate hook

        const handleLogout = async () => {
            try {
                // Optional: You can add a request to your server to handle the logout process (e.g., destroy the session)
                await fetch('/logout.php', { method: 'POST' });
                // Clear any local storage or session variables if needed
                localStorage.removeItem('user'); // Example, if you're storing the user token/info

                // Navigate to the login page
                navigate('/login'); // Use navigate to redirect the user to the login page after logout
            } catch (error) {
                console.error('Error logging out:', error);
            }
        };
    const [openNavCentred, setOpenNavCentred] = useState(false);
    const [navbarBackground, setNavbarBackground] = useState(false);

    const handleScroll = () => {
        const heroSection = document.querySelector('#hero');
        if (window.innerWidth > 992 && heroSection) {
            const heroHeight = heroSection.offsetHeight;
            const triggerHeight = heroHeight - 65; // 65px before the end of the hero section
            const shouldSetBackground = window.scrollY > triggerHeight;

            if (navbarBackground !== shouldSetBackground) {
                setNavbarBackground(shouldSetBackground);
            }
        }
    };

    const handleResize = () => {
        if (window.innerWidth <= 992) {
            setNavbarBackground(true);  // Force bg-light when width <= 992px
        } else {
            handleScroll();  // Run the scroll logic if width > 992px
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        handleResize(); // Initial check on component mount

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [navbarBackground]);


    return (
        <div className={`align-items-center w-100 position-fixed ${navbarBackground ? 'position-fixed' : 'bg-transparent top-0 position-relative text-primary shadow-none'}`} style={{ zIndex: '1' }}>
            <MDBNavbar expand="lg" className={`w-100 px-3 mx-auto nav-wrapper nav ${navbarBackground ? 'bg-glass shadow-5-strong' : 'bg-dark text-primary shadow-none'}`} style={{ top: '20px', zIndex: '1' }}>
                <MDBNavbarBrand href="#" className={`ms-4 my-auto position-fixed ${navbarBackground ? 'd-flex' : 'd-none'}`} style={{ zIndex: '2' }}>
                    <img src={Logo} height="80" alt="" loading="lazy" className="mx-auto my-auto" />
                </MDBNavbarBrand>
                <MDBContainer fluid className="d-flex flex-row justify-content-end">
                    <div className="d-lg-none position-relative" style={{ zIndex: '2' }}>
                        <Hamburger toggled={openNavCentred} toggle={setOpenNavCentred} />
                    </div>
                    <>
                        {openNavCentred && (
                            <div className={`navbar-menu position-fixed p-1 ${navbarBackground ? '' : 'bg-dark bg-opacity-50 text-light shadow-none'}`} style={{ top: '80px' }}>
                                <MDBNavbarNav fullWidth={true} className="mb-0 p-5 mb-lg-0 mx-auto">
                                    <MDBNavbarItem>
                                    <Link to={'/admin'}>
                                        <MDBNavbarLink aria-current="page" href="#top" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                            Hjem
                                        </MDBNavbarLink>
                                    </Link>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <Link to={'/'}>
                                        <MDBNavbarLink aria-current="page" href="https://vipperavalina.dencker.no" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                            Hjemmeside
                                        </MDBNavbarLink>
                                    </Link>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <Link to={'/admin/booking'}>
                                        <MDBNavbarLink aria-current="page" href="#booking" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                            Bookinger
                                        </MDBNavbarLink>
                                        </Link>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <Link to={'/admin/register'}>
                                        <MDBNavbarLink aria-current="page" href="#contact" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                            Registrer ansatt
                                        </MDBNavbarLink>
                                        </Link>
                                    </MDBNavbarItem>
                                    <MDBNavbarItem>
                                        <MDBNavbarLink onClick={handleLogout} aria-current="page" href="#contact" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                            Logg ut
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                </MDBNavbarNav>
                            </div>
                        )}
                    </>
                    <MDBCollapse navbar show={openNavCentred} className="d-lg-flex justify-content-center my-3">
                        <MDBNavbarNav fullWidth={true} className="mb-2 mb-lg-0 mx-auto">
                            <MDBNavbarItem>
                                <Link to={'/admin'}>
                                    <MDBNavbarLink aria-current="page" href="#top" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Hjem
                                    </MDBNavbarLink>
                                </Link>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <Link to={'/'}>
                                    <MDBNavbarLink aria-current="page" href="https://vipperavalina.dencker.no" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Hjemmeside
                                    </MDBNavbarLink>
                                </Link>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <Link to={'/admin/booking'}>
                                    <MDBNavbarLink aria-current="page" href="#booking" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Bookinger
                                    </MDBNavbarLink>
                                </Link>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <Link to={'/admin/register'}>
                                    <MDBNavbarLink aria-current="page" href="#contact" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                        Registrer ansatt
                                    </MDBNavbarLink>
                                </Link>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink onClick={handleLogout} aria-current="page" href="#contact" className={`mx-2 text-uppercase ${navbarBackground ? 'text-dark' : 'text-light'}`}>
                                    Logg ut
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        </div>
    );
};

export default AdminNavbar;
