import React from 'react'
import { Navigate } from 'react-router-dom';
import AdminNavbar from './adminNavbar/AdminNavbar'
import AdminHome from '../adminHome/AdminHome';
import AdminCalendar from './adminCalendar/AdminCalendar';
import Register from '../../components/register/Register';
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';

const AdminPanel = () => {
    const isAuth = localStorage.getItem('auth');

    // If not authenticated, redirect to login
    if (!isAuth) {
        return <Navigate to="/login" />;
    }
  return (
    <>
    <MDBContainer fluid style={{minHeight: '100vh'}} className='h-auto p-0 m-0 w-100 bg-dark bg-gradient'>
      <AdminNavbar />
      <AdminHome />
                  <MDBRow className='w-100 mx-auto' style={{ marginTop: '150px', }}>
              <MDBCol md={5} className='my-2 mx-auto'>
                  <MDBContainer className='h-100'>
                      <Register className='position-relative' style={{ top: '180px' }} />
                  </MDBContainer>
              </MDBCol>
              <MDBCol md={7} className='my-2 mx-auto'>
                  <MDBContainer className='p-4 bg-dark rounded'>
                      <AdminCalendar />
                  </MDBContainer>
              </MDBCol>
          </MDBRow>
              </MDBContainer>
      </>
  )
}

export default AdminPanel