import React, { useState } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBInput,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBTypography,
} from 'mdb-react-ui-kit';
import './Login.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate for redirection
import Logo from '../../../assets/logo.png';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();  // Instantiate useNavigate

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('api/Login/Login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const result = await response.json();

            if (result.success) {
                setMessage('Login successful!');
                // Save token or login status (if needed)
                localStorage.setItem('auth', true);  // Save login status to localStorage
                navigate('/admin');        // Redirect to admin dashboard
            } else {
                setMessage(result.message || 'Login failed!');
            }
        } catch (error) {
            setMessage('An error occurred. Please try again.');
        }
    };

    return (
        <MDBContainer fluid className="bg-light vh-100 p-5 d-flex align-items-center justify-content-center">
        <MDBContainer className="login-container d-flex align-items-center justify-content-center h-100">
                <MDBCol md="5" className="d-flex flex-column text-dark text-center col-4 mx-4">
                    <img src={Logo} alt="" className='w-50 m-auto my-4' />
                    <p>Velkommen til innloggingssiden for ansatte. Her får du tilgang til adminpanelet hvor du kan administrere timebestillinger, registrere nye kunder og holde oversikt over viktige data for salongen. Ved å logge inn får du en enkel og effektiv måte å håndtere alt fra daglige oppgaver til langsiktig planlegging.</p>
                    <p>Sikkerhet er viktig for oss, så all din informasjon er trygt lagret og kun tilgjengelig for deg som ansatt. Vennligst logg inn med brukernavn og passord for å komme i gang!</p>

                </MDBCol>

                <MDBCol md="8" className="d-flex rounded p-4 flex-column bg-white shadow-4-strong h-50 justify-content-center mx-4 justify-comtemt-center align-items-center text-center col-4">
                    <MDBTypography tag='h1' className="text-center text-dark mt-3">Velkommen tilbake</MDBTypography>
                <MDBCardText className="text-center text-muted">
                    Vennligst skriv ditt brukernavn & passord
                </MDBCardText>
                    <form onSubmit={handleLogin} className='w-75'>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBInput
                                    label="Username"
                                    id="username"
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12">
                                <MDBInput
                                    label="Password"
                                    id="password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="mt-4">
                            <MDBCol md="12" className="text-center">
                                <MDBBtn type="submit" color="primary">
                                    Log In
                                </MDBBtn>
                            </MDBCol>
                        </MDBRow>
                    </form>

                    {message && <p className="text-center text-danger mt-4">{message}</p>}
                    </MDBCol>
        </MDBContainer>
    </MDBContainer>
    );
};

export default Login;
