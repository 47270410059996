import React, { useEffect, useState } from 'react';
import { MDBCalendar } from 'mdb-react-calendar';
import { MDBContainer } from 'mdb-react-ui-kit';
import './AdminCalendar.css'

// Define service durations (in minutes)
const serviceDurations = {
    'Vipper': 120, // 2 hours
    'Negler': 30,  // 30 minutes
    'Hair Dying': 30 // 30 minutes
    // Add more services as needed
};

const AdminCalendar = () => {
    const [bookings, setBookings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await fetch('/api/Booking/fetchBookings.php');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                // Validate and set bookings
                const validBookings = data.filter(booking => booking.date && booking.time && booking.name && booking.job_type);
                setBookings(validBookings);
            } catch (error) {
                setError(`Error fetching bookings: ${error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, []);

    // Handle loading state
    if (loading) {
        return <p>Laster tilgjenglige tider...</p>;
    }

    // Handle error state
    if (error) {
        return <p>{error}</p>;
    }

    // Convert bookings to events for the calendar
    const events = bookings.map(booking => {
        const { date, time, name, job_type } = booking;

        try {
            const formattedDate = new Date(date).toLocaleDateString('no-NO');
            const startTime = new Date(`${date}T${time}`);
            const endTime = new Date(startTime);

            // Calculate end time based on job type
            const duration = serviceDurations[job_type] || 60; // Default to 1 hour if service not found
            endTime.setMinutes(endTime.getMinutes() + duration);

            const formattedStartTime = `${String(startTime.getHours()).padStart(2, '0')}:${String(startTime.getMinutes()).padStart(2, '0')}`;
            const formattedEndTime = `${String(endTime.getHours()).padStart(2, '0')}:${String(endTime.getMinutes()).padStart(2, '0')}`;

            return {
                summary: `${name} - ${job_type}`,
                start: {
                    date: formattedDate,
                    time: formattedStartTime,
                },
                end: {
                    date: formattedDate,
                    time: formattedEndTime,
                },
                color: {
                    background: '#3788d8',
                    foreground: '#ffffff',
                },
            };
        } catch (e) {
            console.error('Error parsing date/time:', booking, e);
            return null;
        }
    }).filter(event => event !== null);

    console.log('Events passed to MDBCalendar:', events);

    return (
        <MDBContainer className='adminCalendar_wrapper rounded'>
            <MDBCalendar blur readonly disableEventButton events={events} />
        </MDBContainer>
    );
};

export default AdminCalendar;
