import { MDBCalendar } from 'mdb-react-calendar'
import { MDBBtn, MDBBtnGroup, MDBCol, MDBContainer, MDBRow, MDBTypography } from 'mdb-react-ui-kit'
import React from 'react'
import Register from '../../components/register/Register'
import AdminCalendar from '../adminDashboard/adminCalendar/AdminCalendar'
import AdminNavbar from '../adminDashboard/adminNavbar/AdminNavbar'
import './AdminHome.css'

const AdminHome = () => {
  return (
    <>
    <MDBContainer fluid className='h-100'>
          <MDBContainer className='bg-dark p-5 my-auto rounded my-5 position-relative' style={{top: "120px"}}>
              <MDBTypography tag='h3' className='text-light'>
                  Velkommen tilbake, (brukernavn).
              </MDBTypography>
        <p className='text-muted my-4'>Her kan du enkelt administrere salongen din. Du har muligheten til å registrere nye brukere, se oversikt over alle timebestillinger og legge til nye bookinger.</p>
              <MDBBtn className='m-2 btn-lg'>Endre passord</MDBBtn>
              <MDBBtn className='m-2 btn-lg'>Se bookinger</MDBBtn>
          </MDBContainer>
        </MDBContainer>
    </>
  )
}

export default AdminHome